function Skills() {
    return (
        <section id="skills" className="text-light">
            <div className="container py-5">
                <h2 className="mb-5">My Skills</h2>
                <div className="row g-4">
                    <div className="col-sm-2 col-4 align-content-center">
                        <div className="d-flex skill-card mx-auto rounded shadow-lg">
                            <img className="img-fluid m-2 card-img-top skill-img" src="/images/technology/angular.svg" alt=""></img>
                        </div>
                    </div>
                    <div className="col-sm-2 col-4 align-content-center">
                        <div className="d-flex skill-card mx-auto rounded shadow-lg">
                            <img className="img-fluid m-2 card-img-top skill-img" src="/images/technology/typescript.svg" alt=""></img>
                        </div>
                    </div>
                    <div className="col-sm-2 col-4 align-content-center">
                        <div className="d-flex skill-card mx-auto rounded shadow-lg">
                            <img className="img-fluid m-2 card-img-top skill-img" src="/images/technology/javascript.svg" alt=""></img>
                        </div>
                    </div>
                    <div className="col-sm-2 col-4 align-content-center">
                        <div className="d-flex skill-card mx-auto rounded shadow-lg">
                            <img className="img-fluid m-2 card-img-top skill-img" src="/images/technology/jquery-v1.svg" alt=""></img>
                        </div>
                    </div>
                    <div className="col-sm-2 col-4 align-content-center">
                        <div className="d-flex skill-card mx-auto rounded shadow-lg">
                            <img className="img-fluid m-2 card-img-top skill-img" src="/images/technology/react.svg" alt=""></img>
                        </div>
                    </div>
                    <div className="col-sm-2 col-4 align-content-center">
                        <div className="d-flex skill-card mx-auto rounded shadow-lg">
                            <img className="img-fluid m-2 card-img-top skill-img" src="/images/technology/html-5-1.svg" alt=""></img>
                        </div>
                    </div>
                    <div className="col-sm-2 col-4 align-content-center">
                        <div className="d-flex skill-card mx-auto rounded shadow-lg">
                            <img className="img-fluid m-2 card-img-top skill-img" src="/images/technology/css-3.svg" alt=""></img>
                        </div>
                    </div>
                    <div className="col-sm-2 col-4 align-content-center">
                        <div className="d-flex skill-card mx-auto rounded shadow-lg">
                            <img className="img-fluid m-2 card-img-top skill-img" src="/images/technology/svelte.svg" alt=""></img>
                        </div>
                    </div>
                    <div className="col-sm-2 col-4 align-content-center">
                        <div className="d-flex skill-card mx-auto rounded shadow-lg">
                            <img className="img-fluid m-2 card-img-top skill-img" src="/images/technology/nodejs.svg" alt=""></img>
                        </div>
                    </div>
                    <div className="col-sm-2 col-4 align-content-center">
                        <div className="d-flex skill-card mx-auto rounded shadow-lg">
                            <img className="img-fluid m-2 card-img-top skill-img" src="/images/technology/express-v1.svg" alt=""></img>
                        </div>
                    </div>
                    <div className="col-sm-2 col-4 align-content-center">
                        <div className="d-flex skill-card mx-auto rounded shadow-lg">
                            <img className="img-fluid m-2 card-img-top skill-img" src="/images/technology/mongodb.svg" alt=""></img>
                        </div>
                    </div>
                    <div className="col-sm-2 col-4 align-content-center">
                        <div className="d-flex skill-card mx-auto rounded shadow-lg">
                            <img className="img-fluid m-2 card-img-top skill-img" src="/images/technology/mysql.svg" alt=""></img>
                        </div>
                    </div>
                    <div className="col-sm-2 col-4 align-content-center">
                        <div className="d-flex skill-card mx-auto rounded shadow-lg">
                            <img className="img-fluid m-2 card-img-top skill-img" src="/images/technology/aws-v1.svg" alt=""></img>
                        </div>
                    </div>
                    <div className="col-sm-2 col-4 align-content-center">
                        <div className="d-flex skill-card mx-auto rounded shadow-lg">
                            <img className="img-fluid m-2 card-img-top skill-img" src="/images/technology/gulp.svg" alt=""></img>
                        </div>
                    </div>
                    <div className="col-sm-2 col-4 align-content-center">
                        <div className="d-flex skill-card mx-auto rounded shadow-lg">
                            <img className="img-fluid m-2 card-img-top skill-img" src="/images/technology/webpack.svg" alt=""></img>
                        </div>
                    </div>
                    <div className="col-sm-2 col-4 align-content-center">
                        <div className="d-flex skill-card mx-auto rounded shadow-lg">
                            <img className="img-fluid m-2 card-img-top skill-img" src="/images/technology/java.svg" alt=""></img>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Skills;