interface ProjectProps {
  project: any
}

const skillPillColor: string[] = [
  'secondary'
];

export function ProjectCard({project}: ProjectProps) {
    return (
        <>
            <div className="col-sm-4">
              <div className="card bg-dark text-white m-2">
                <div className="card-body">
                  <div>
                    <span className="card-title">{project.title}</span>
                    {
                      project.link && <a href={project.link} className="external-link" target="_blank">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-up-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5"/>
                        <path fill-rule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0z"/>
                      </svg>
                    </a>
                    } 
                  </div>
                  <div className="mt-2 mb-2">
                    {
                      project?.skills?.map(function(skill: string) {
                        const color: string = skillPillColor[Math.floor(Math.random() * 1)];
                        const classStr = 'badge rounded-pill me-1 text-bg-' + color;
                        return (<span  key={skill} className={classStr}>{skill}</span>)
                      })
                    }
                  </div>
                  <p className="card-text">
                    <small>
                    {project.description}
                    </small>
                  </p>
                </div>
              </div>
            </div>
        </>
    );
}