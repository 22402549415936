export function Intro() {
    return (
      <>
        <section className='pt-4 align-content-center text-light intro-container'>
          <div className="container">
            <div className="d-sm-flex justify-content-between">
              <div className='py-5 py-sm-0 text-sm-start text-center intro-name-container'>
                <h1 className='text-warning' style={{textShadow: "0 0 5px #bdb412f0"}}>Anas Imam</h1>
                <span className='text-warning h1' style={{textShadow: '0 0 5px #bdb412f0;'}}>Full Stack Developer</span>
                <ul className='sections-ul'>
                  <li  className='sections-li'>
                    <a className="sections-link" href="#experience">EXPERIENCE</a>
                  </li>
                  <li  className='sections-li'>
                    <a className="sections-link" href="#projects">PROJECTS</a>
                  </li>
                  <li  className='sections-li'>
                    <a className="sections-link" href="#skills">SKILLS</a>
                  </li>
                  <li  className='sections-li'>
                    <a className="sections-link" href="#connect">LET'S CONNECT!</a>
                  </li>
                </ul>
                <a href="/Resume.pdf" type='button' target='_blank' className="btn btn-warning btn-resume beat-style text-dark">Download Resume</a>
              </div>
              <div className='text-center text-sm-start w-50 d-inline'>
                <p>
                Senior Software Engineer with diverse experience in developing and maintaining web applications. I have worked on web applications using JavaScript and top front-end frameworks, and Java, NodeJS on back-end.
                </p>
                <p>
                I have experience in developing front end applications, browser extensions, google addons and third party plugins using top client side frameworks including jQuery, Angular, React, Svelte. Good hands on experience on build systems like gulp, webpack, rollup. Experienced in writing E2E test cases using protractor, puppeteer, jest and integration with BrowserStack.
                </p>
                <p>
                I have also experience of working on back-end applications using Java, MySQL, Redshift, NodeJS and deployment of web applications on Amazon EC2 instances using teamcity. Worked on Amazon services integration like S3, Amazon SES, Amazon SNS, SQS.
                </p>
                <p>
                I am hardworking and dedicated individual and excellent team player. 
                </p>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
  