export function Experience() {
    return (
        <section id="experience" className="py-4">
            <div className="container text-white ">
                <h2 className="mb-5">Experience</h2>
                <div className="p-4 " style={{boxShadow: '0 0 25px #555', background: '#0c0c18'}}>
                    <div className="d-sm-flex justify-content-between text-start border-bottom border-secondary pb-4">
                        <div><h5>wizdom.ai</h5></div>
                        <div className="w-75 d-inline">
                            <div>
                                <div className="overview">
                                    <h5>
                                        Senior Software Engineer
                                    </h5>
                                    <div>Full Time</div>
                                    <div>March 2015 - Present</div>
                                    <div>Karachi</div>
                                </div>
                                <div className="description mt-3">
                                    <small>
                                        <ul>
                                            <li>
                                            Developed and maintained web applications using JavaScript and top front-end
                                            frameworks such as jQuery, Angular, React, and Svelte.
                                            </li>
                                            <li>
                                            Designed and implemented browser extensions, Google addons, and third-party plugins to
                                            enhance web application functionality.
                                            </li>
                                            <li>
                                            Built and optimized build systems using Gulp, Webpack, and Rollup to streamline the
                                            development process.
                                            </li>
                                            <li>
                                            Implemented and executed E2E test cases using Protractor, Puppeteer, and Jest,
                                            ensuring the quality and performance of web applications.
                                            </li>
                                            <li>
                                            Deployed web applications on Amazon EC2 instances using TeamCity, ensuring smooth
                                            and efficient deployment processes.
                                            </li>
                                            <li>
                                            Integrated Amazon services including S3, SES, SNS, and SQS to enhance web application
                                            features and functionalities.
                                            </li>
                                            <li>
                                            Enhanced file upload and download functionality by integrating AWS S3 with signature
                                            version 4 and implementing temporary role-based credentials. This approach facilitates the
                                            generation of pre-signed URLs for secure file uploads and downloads, ensuring robust
                                            security measures and streamlined access control.
                                            </li>
                                            <li>
                                            Supervised the migration and deployment of wizdom.ai addons, transitioning from vanilla
                                            JavaScript to Angular 13. My role encompassed orchestrating seamless coordination
                                            among teams, meticulously breaking down tasks, and conducting thorough code reviews
                                            to ensure a smooth and successful transition.
                                            </li>
                                        </ul>
                                    </small>
                                </div>
                            </div>
                            <div className="pt-4">
                                <div className="overview">
                                    <h5>
                                        Software Engineer
                                    </h5>
                                    <div>Full Time</div>
                                    <div>December 2013 - February 2015</div>
                                    <div>Karachi</div>
                                </div>
                                <div className="description mt-3">
                                    <small>
                                        <ul>
                                            <li>
                                            Developed and maintained back-end applications using Java, MySQL, Redshift, and
                                            NodeJS.
                                            </li>
                                            <li>
                                            Collaborated with cross-functional teams to design and implement web applications that
                                            meet business requirements.
                                            </li>
                                            <li>
                                            Implemented CI/CD pipelines and performed code reviews to ensure code quality and
                                            adherence to coding standards.
                                            </li>
                                            <li>
                                            Optimized database queries and implemented caching mechanisms to improve application
                                            performance.
                                            </li>
                                            <li>
                                            Worked on continuous improvement initiatives and adopted best practices to enhance
                                            software development processes.
                                            </li>
                                        </ul>
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-white d-sm-flex justify-content-between text-start pt-4">
                        <div className="w-25"><h5>SSGC</h5></div>
                        <div className="w-75">
                            <div>
                                <h5>
                                    Intern
                                </h5>
                                <div>June 2013 - July 2013</div>
                                <div>Karachi</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}