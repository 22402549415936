import { ProjectCard } from "./ProjectCard";
import Skills from "./Skills";

const projects = [
  {
    id: '1',
    title: 'TenPercent',
    description: `This project aims to develop a social media platform dedicated to the community with special needs or challenges, offering a secure and accessible environment for individuals to share their life memories and experiences. The platform will be available on iOS and Android, featuring functionalities similar to Facebook, such as posting updates, creating events, and a comprehensive resource directory specifically tailored for the community with special needs.
    Major responsibilities include creating architecture, database models designing and development of backend application, APIs development and authentications. Include integration of facebook and apple login/signup authentication. Using Passport as authentication middleware.`,
    image: '/images/projects/1.svg',
    skills: ['NodeJS', "Express", "MongoDB", "Mongoose", "PassportJS", "JWT", "AWS"]
  }, {
    id: '2',
    title: 'wizdom.ai Web',
    description: `Major responsibility include wizdom.ai Dashboards, Query optimizations, Chrome/Edge Extension, Bookmarklet, Reviewer Recommendation, Organization Admin Panel, Google Recaptcha integration, MFA, Snapshot/E2E testing using Jest, Protractor/Puppeteer(integration with BrowserStack), Sentry/Piwik Integrations, developing APIs for dashboards and other applications, cookies handling, Security headers and ITP(Intelligent Tracking Protection) handling for browsers.`,
    image: '/images/projects/2.svg',
    skills: ['TypeScript', 'Angular', 'MySQL', 'AWS', 'Java', 'WebComponents', 'Webpack', 'Gulp.js']
  }, {
    id: '3',
    title: 'wizdom.ai Chrome Extension',
    description: `wizdom extension is a monorepo based project that bundles multiple projects that are somehow related to the extension. The purpose behind using a monorepo is to split up a large codebase such as wizdom extension into separate independently versioned packages which is extremely useful for code sharing and management. That makes it easier to maintain code and logically divide code in packages. With a single command (build, test, prod build, publish) at project root you can perform an action in all the packages.`,
    image: '/images/projects/3.svg',
    skills: ['Typescript', 'jQuery', 'Gulp.js', 'Handlebars.js', 'Browser Extensions', 'Lerna']
  }, {
    id: '4',
    title: 'FileShare',
    description: `FileShare application built using NodeJS, ExpressJS, MongoDB (mongoose client) on backend and Angular 13 for frontend.

    FileShare allows you to upload files and share them with your friends or co-workers. Simply upload your file and share it with your friends. Integrated SendInBlue to send emails for shared files. Client side and backend resides on different domains and CORS is handled for API calls from clients. Application is deployed on Heroku cloud platform.`,
    image: '/images/projects/1.svg',
    skills: ['MongoDB', 'Express', 'Angular', 'NodeJS']
  }, {
    id: '5',
    title: '2048',
    description: `Classic 2048 game built with Angular 12 and converted to hybrid app using Apache Cordova with Android target. Ng material ui is being used as UI library. The application is deployed on Google play store. Features of this game include grid customisation, dark mode, move sounds, full screen and locked layout, high scores management for different difficulty levels. Google admob banner ads are also integrated`,
    image: '/images/projects/2.svg',
    skills: ['Angular', 'Cordova', 'Ng Material UI' ,'Google Admob'],
    link: "https://ng2048.vercel.app/"
  }, {
    id: '6',
    title: 'Minesweeper',
    description: `Classic minesweeper game built with Angular 12 and converted to hybrid app using Apache Cordova with Android target. Ng material ui is being used as UI library. The application is deployed on Google play store. Features of this game include grid customisation, dark mode, move sounds, full screen and locked layout, high scores management for different difficulty levels. Google admob banner ads are also integrated`,
    image: '/images/projects/3.svg',
    skills: ['Angular', 'Cordova', 'Ng Material UI' ,'Google Admob'],
    link: "https://ng-minesweeper.vercel.app/"
  }, {
    id: '7',
    title: 'Intelligent & Configurable Queuing Systems (ICQS)',
    description: `Intelligent and Configurable Queuing System (ICQS) is a mobile application for handling problems associated with present Queuing Systems; the token based system, and the appointment system. The estimated serving time is calculated by the app after analyzing what service customer wants from the service provider (as service provider may be offering different services), how many people are already in the queue ahead of customer. The estimated time will keep on changing dynamically (as some people might not show up and some people might take longer/shorter time to serve). It also considers the number of servers serving while calculating the estimated time of serving. The estimated time in case of multiple server queue is calculated using the technique of Shortest Queue Join.

    Worked on this project as a Final Year Project for my Masters in Computer Science. Implemented android application using Android Studio and back-end on node.js using expressjs, MySQL, Firebase Cloud Messasging for push notifications. Hosted back-end application and MySQL database on Amazon EC2 instance`,
    image: '/images/projects/1.svg',
    skills: ['Android', 'NodeJS', 'Express', 'MySQL', 'Firebase', 'AWS']
  }, {
    id: '8',
    title: 'wizdom.ai Interactive PDF Reader',
    description: `The wizdom.ai Interactive PDF Reader allows you to transform standard PDFs into interactive pages on which you can make digital annotations.

    On the wizdom.ai online and desktop library, users can utilize the Interactive PDF reader to open all PDF attachments and instantly start highlighting text and add comments to figures and key information. All of this is saved directly on the cloud and can be accessed by any device anywhere in the world.
    
    It is developed using Typescript with jQuery using PDFJS library for PDF rendering and handlebars templates for HTML templating.`,
    image: '/images/projects/2.svg',
    skills: ['Typescript', 'jQuery', 'Handlebars.js', 'PDFJS']
  }, {
    id: '9',
    title: 'Wordle Extreme',
    description: ` Created a Wordle Extreme game utilizing React 18, I implemented dynamic
    features and seamless interactions, ensuring a smooth and captivating gameplay
    experience. Additionally, I implemented state management using React Redux,
    allowing for efficient state management and seamless synchronization between
    components. Through this project, I demonstrated my proficiency in modern web
    development frameworks and my ability to deliver high-quality, user-centric
    solutions`,
    image: '/images/projects/3.svg',
    skills: ['ReactJS', 'Bootstrap'],
    link: "https://wordle-extreme-two.vercel.app/"
  }
];

function Projects() {
    return (
      <>
        <section id="projects" className='text-light' style={{background: '#0000007a'}}>
          <div className='container py-5'>
            <h2 className="mb-5">My Projects</h2>
            <div className="row text-start">
              {
                projects.map(function(project: any, index) {
                  return (
                    <ProjectCard key={project.id} project={project}></ProjectCard>
                  )
                })
              }
            </div>
          </div>
        </section>
      </>
    );
  }

  export default Projects;