export function Footer() {
    return (
        <>
            <section id="connect" className="py-1" style={{background: "#0000007a"}}>
                <div className="container text-secondary">
                    <footer className="d-sm-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-secondary border-top">
                        <div className="col-md-4 d-sm-flex align-items-center">
                            <a href="#" className="mb-3 me-2 mb-md-0 text-decoration-none lh-1">
                            <img className="img-fluid m-2 connect-svg" src="/logo192.png" alt=""></img>
                            </a>
                            <span className="mb-3 mb-md-0">© 2024 All Rights Reserved</span>
                        </div>
                        <div className="col-md-8 justify-content-center justify-content-sm-end list-unstyled d-sm-flex">
                            <div className="me-sm-3">
                                <a rel="noopener" href="mailto:manasimam@gmail.com" className="float-sm-end text-decoration-none"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16">
                                <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z"/>
                                </svg>  manasimam@gmail.com</a>
                                <br/>
                                <span className="float-sm-end text-light">
                                    <a rel="noopener" href="tel:+923453354888" className="text-decoration-none"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone" viewBox="0 0 16 16">
                                    <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"/>
                                    </svg>  +92-345-3354888</a>  
                                    <span> / </span>
                                    <a rel="noopener" href="tel:+923162512776" className="text-decoration-none"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone" viewBox="0 0 16 16">
                                    <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"/>
                                    </svg>  +92-316-2512776</a>
                                </span>
                            </div>
                            <ul className="nav justify-content-center">
                                <li><a rel="noreferrer" href="https://www.facebook.com/manasimam" target="_blank"><img className="img-fluid m-2 connect-svg" src="/images/connect/facebook.svg" alt=""></img></a></li>
                                <li><a rel="noreferrer" href="https://www.instagram.com/manasimam/" target="_blank"><img className="img-fluid m-2 connect-svg" src="/images/connect/instagram.svg" alt=""></img></a></li>
                                <li><a rel="noreferrer" href="https://www.linkedin.com/in/anas-imam-71604a84/" target="_blank"><img className="img-fluid m-2 connect-svg" src="/images/connect/linkedin.svg" alt=""></img></a></li>
                            </ul>
                        </div>
                    </footer>
                </div>
            </section>
        </>
    )
}