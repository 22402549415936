import React from 'react';
import './App.css';
import Projects from './components/Projects';
import { Intro } from './components/Intro';
import Skills from './components/Skills';
import ParticlesComponent from './components/particles';
import { Footer } from './components/Footer';
import { Experience } from './components/Experience';

function App() {
  return (
    <div className="App">
      <ParticlesComponent/>
      <Intro />
      <Experience />
      <Projects />
      <Skills />
      <Footer />
    </div>
  );
}

export default App;
